import { useEffect, useState } from "react";
import "./App.css";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import ApplicationRouter from "./routing/ApplicationRouter";



function App() {
  const [config, setConfig] = useState(null);

  const fetchConfig = async () => {
    const response = await fetch('config.json');
    const config = await response.json();
    setConfig(config)
    console.log(config);
  }

  useEffect(() => {
    fetchConfig();
  }, []);

  if (!config) {
    return <div>Config missing</div>;
  }

  return (
    <div className="App">
      <Header config={config} />
      <ApplicationRouter pages={config.pages} home={config.home} />
      <Footer />
    </div>
  );
}

export default App;
