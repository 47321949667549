import React from "react";
import { Route, Routes } from "react-router-dom"
import Home from "../home/Home"
import Pages from "../pages/Pages";

const ApplicationRouter = ({ pages, home }) => {
    console.log(pages)
    return <Routes>
        <Route path="/" element={<Home content={home} />}></Route>
        {pages.map(page =>
            <Route
                key={page.name}
                path={`/${page.name}`}
                element={<Pages content={page.content} />}
            ></Route>
        )}
    </Routes>
};

export default ApplicationRouter;